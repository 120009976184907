<template>
  <Modal :title="ModalTitle" :is-show="isShow" v-if="isShow" @close-modal="setVisible(false)">
    <div class="edit-account">
      <table>
        <!--姓名-->
        <tr>
          <td class="label">{{$t('Name')}}：</td>
          <td><input class="ipt" type="text" autocomplete="off" :placeholder="$t('Name')" v-model.trim="userName"/></td>
        </tr>
        <!--帳號-->
        <tr>
          <td class="label">{{$t('Account')}}：</td>
          <td><input class="ipt" type="text" autocomplete="off" :placeholder="$t('placeholderEMail')" :disabled="userId"
                     v-model.trim="userAccount"/></td>
        </tr>
        <!--密碼-->
        <tr>
          <td class="label">{{$t('Password')}}：</td>
          <td>
            <button class="button small" v-if="userId&&!changePassword" @click="changePassword=true;showPassword='';confirmPassword=''">
              {{$t('Reset password')}}
            </button>
            <input class="ipt" type="password" autocomplete="off" :placeholder="$t('Password')" maxlength="16"
                   v-model.trim="showPassword" v-else/>
          </td>
        </tr>
        <!--確認密碼-->
        <tr v-if="!(userId&&!changePassword)">
          <td class="label">{{$t('ConfirmPassword')}}：</td>
          <td>
            <input class="ipt" type="password" autocomplete="off" :placeholder="$t('ConfirmPassword')" maxlength="16"
                   v-model.trim="confirmPassword"/>
          </td>
        </tr>
        <!--角色-->
        <tr>
          <td class="label">{{$t('Role')}}：</td>
          <td>
            <el-select class="select" v-model="roleType" size="small" :placeholder="$t('PleaseChoose')">
              <el-option v-for="item in roleList.filter(r=>r.id!=='root')"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </td>
        </tr>
        <!--状态-->
        <tr>
          <td class="label">{{$t('Status')}}：</td>
          <td>
            <radio v-model="isValid" v-for="(value,key) of $t('statusObj')" :label="key" :key="key">{{value}}</radio>
          </td>
        </tr>
      </table>
    </div>
    <div class="actions">
      <button class="button button-primary m-r" :disabled="disableSubmit" @click="onSubmit">{{$t('OK')}}</button>
      <button class="button" @click="setVisible(false)">{{$t('Cancel')}}</button>
    </div>
  </Modal>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Radio from '../../../../components/Radio';
  import {saveAdminAccount, getAdminAccountDetail} from '../../../../api/admin-account';

  export default {
    name: 'EditAccount',
    props: {
      show: Boolean,
      userId: String
    },
    data() {
      return {
        isShow: false,
        userName: '',
        userAccount: '',
        showPassword: '',
        confirmPassword: '',
        isValid: 'Y',
        roleType: '',
        changePassword: false
      };
    },
    computed: {
      ...mapGetters(['roleList']),
      ModalTitle() {
        return this.userId ? this.$t('ModifyAccount') : this.$t('Add account');
      },
      disableSubmit: function () {
        return !this.userName || !this.userAccount || !this.showPassword || !this.confirmPassword || !this.roleType;
      }
    },
    components: {Radio},
    methods: {
      setVisible(visible) {
        this.changePassword = false;
        this.$emit('update:show', visible);
      },
      onSubmit() {
        let datas = {
          userId: this.userId || '',
          userName: this.userName,
          userAccount: this.userAccount,
          showPassword: this.showPassword,
          confirmPassword: this.confirmPassword,
          roleType: this.roleType,
          isValid: this.isValid
        };
        // if (!isEmail(datas.userAccount)) {
        //   this.$message.warning('輸入的帳號錯誤！');
        //   return false;
        // }
        if (datas.showPassword !== datas.confirmPassword) {
          this.$message.warning('兩次密碼不一致！');
          return false;
        }
        saveAdminAccount(datas).then(res => {
          this.$emit('reload-list', 1);
          this.$message.success(this.$t('SaveSuccessfully'));
          this.setVisible(false);
        });
      }
    },
    watch: {
      show: function (value) {
        this.isShow = value;
      },
      userId: function (value) {
        if (!value) {
          const isShow = this.isShow;
          Object.assign(this.$data, this.$options.data());
          this.isShow = isShow;
        } else {
          getAdminAccountDetail(value).then(res => {
            let datas = res.value;
            datas.confirmPassword = datas.showPassword;
            Object.assign(this.$data, res.value);
          });
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .edit-account{
    text-align:left;padding:24px 30px;font-size:16px;
    td{padding-bottom:20px;vertical-align:middle;}
    .label{
      width:80px;white-space:nowrap;
    }
    .ipt{
      width:330px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{display:block;}
    /deep/ .radio{
      vertical-align:top;
      .label{color:#321908;}
    }
  }
  .actions{padding:4px 0 24px;}
</style>
