<template>
  <div class="main">
    <div class="filter cl">
      <!--姓名-->
      <div class="item cl">
        <div class="label">{{$t('Name')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.userName"/>
        </div>
      </div>
      <!--帐号-->
      <div class="item cl">
        <div class="label">{{$t('Account')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.userAccount"/>
        </div>
      </div>
      <!--状态-->
      <div class="item cl">
        <div class="label">{{$t('Status')}}：</div>
        <div class="fl">
          <el-select class="select" clearable v-model="filterData.isValid" size="small" :placeholder="$t('All')">
            <el-option v-for="item in statusOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--操作按钮-->
      <div class="fl">
        <button class="button button-primary small" type="button" @click="getList(1)">{{$t('search')}}</button>
      </div>
    </div>
    <div>
      <button class="button m-r" type="button">{{$t('BatchDeletion')}}</button>
      <button class="button button-primary" type="button" @click="setEditModal()">{{$t('Add account')}}</button>
    </div>
    <div class="table border">
      <table>
        <thead>
        <tr>
          <th class="checkbox"><img src="../../../assets/images/checkbox.png"/></th>
          <th>{{$t('Role')}}</th>
          <th>{{$t('Name')}}</th>
          <th>{{$t('Account')}}</th>
          <th>{{$t('Status')}}</th>
          <th width="60">{{$t('Operating')}}</th>
        </tr>
        </thead>
        <tbody class="tbody">
        <tr v-for="(item,index) of list" :key="item.userId">
          <td class="checkbox"><img src="../../../assets/images/checkbox.png"/></td>
          <div v-if="isROleList(roleList, item.roleType)">
            <td>{{item.roleType|dictName(roleList,'name','id')}}</td>
          </div>
          <div v-else>
            <td>未指定角色</td>
          </div>
          <td>{{item.userName}}</td>
          <td>{{item.userAccount}}</td>
          <td :class="item.isValid==='Y'?'green':'red'">{{$t('statusObj')[item.isValid]}}</td>
          <td class="nowrap">
            <template v-if="item.userId!==adminUserId">
              <a class="btn-item" href="javascript:;" @click="setEditModal(item.userId)">
                <img src="../../../assets/images/icon/pen.png"/>
              </a>
              <a class="btn-item" href="javascript:;" @click="deleteAccount(index,item.userId)">
                <img src="../../../assets/images/icon/ashcan.png"/>
              </a>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
      <empty v-if="isEmpty" :title="$t('NoData')"/>
      <el-pagination class="pages txt-r"
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="changePage"
                     hide-on-single-page>
      </el-pagination>
    </div>
    <edit-account :show.sync="showEditModal" :user-id="currentUserId" @reload-list="getList"/>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import EditAccount from './components/EditAccount';
  import Empty from '../../../components/Empty';
  import {getAdminAccountList, deleteAdminAccount} from '../../../api/admin-account';

  export default {
    name: 'AdminAccountManagement',
    data() {
      return {
        showEditModal: false,
        list: [],
        totalPages: 1,
        isEmpty: false,
        currentUserId: '',
        statusOptions: [
          {value: 'Y', label: this.$t('statusObj')['Y']},
          {value: 'N', label: this.$t('statusObj')['N']}
        ],
        filterData: {
          userName: '',
          userAccount: '',
          isValid: ''
        }
      };
    },
    computed: {...mapGetters(['adminUserId', 'roleList'])},
    components: {Empty, EditAccount},
    created() {
      console.log(this.$store.getters.roleList);
      this.getList();
    },
    methods: {
      // 判断角色是否生效
      isROleList (roleList, value) {
        var roleValue = roleList.map((item) => {
          return item.id;
        });
        return roleValue.includes(value);
      },
      // 新增或编辑账号弹窗
      setEditModal(userId) {
        this.currentUserId = userId;
        this.showEditModal = true;
      },
      // 获取帐号列表
      getList(page = 1) {
        const data = Object.assign({page, perPage: 10}, this.filterData);
        getAdminAccountList(data).then(res => {
          const {value} = res;
          this.list = value.list || [];
          this.totalPages = value.totalPages;
          this.isEmpty = data.page === 1 && !this.list.length;
        });
      },
      changePage(page) {
        this.getList(page);
      },
      // 删除帐号
      deleteAccount(index, userId) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: this.$t('confirm.delete'),
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteAdminAccount(userId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success('刪除成功!');
                this.list.splice(index, 1);
                if (!this.list.length) {
                  this.getList();
                }
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(() => {
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .green{color:#00b680;}
    .red{color:#ff6f61;}
  }
  .filter{
    .item{
      float:left;margin-bottom:24px;margin-right:24px;vertical-align:top;
      .label{float:left;line-height:32px;}
    }
    .ipt{
      width:160px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .select{width:160px;vertical-align:top;}
  }
</style>
