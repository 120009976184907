import request from '@/utils/request';
// import {getLocale} from '../utils/language';

// const language = getLocale() || 'zh-hk';

// 获取帐号列表（分页）
export function getAdminAccountList(data) {
  return request({
    url: '/admin/account/list',
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 获取帐号详情
export function getAdminAccountDetail(userId) {
  return request({
    url: `/admin/account/details/${userId}`,
    baseURL: '/v2',
    method: 'get'
  });
}

// 保存帐号
export function saveAdminAccount(data) {
  return request({
    url: '/admin/account/save',
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 删除账号
export function deleteAdminAccount(userId) {
  return request({
    url: `/admin/account/delete/${userId}`,
    baseURL: '/v2',
    method: 'post'
  });
}
