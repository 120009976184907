<template>
  <div class="radio" @click="selected">
    <img class="ico"
         :src="label===$attrs.value?require('../assets/images/radio_selected.png'):require('../assets/images/radio.png')"/>
    <span class="label">
      <slot></slot>
    </span>
  </div>
</template>
<script>
  export default {
    name: 'Radio',
    props: {
      label: {
        type: String,
        default: ''
      }
    },
    methods: {
      selected() {
        this.$emit('input', this.label);
      }
    }
  };
</script>
<style scoped lang="less">
  .radio{
    display:inline-block;margin-right:30px;vertical-align:middle;cursor:pointer;user-select:none;
    .ico{display:inline-block;width:16px;height:16px;vertical-align:middle;}
    .label{display:inline-block;padding-left:8px;vertical-align:middle;color:#7b7b7b;}
    &.medium{
      .label{color:#321908;font-size:16px;}
    }
  }
</style>
